import { useCallback, useMemo } from 'react'
import { Trans } from '@lingui/macro'

import background from '../../../../assets/background/background_1.jpg'
import coinIcon from '../../../../assets/icon/NAOSCoin.png'
import textIcon from '../../../../assets/logo/NAOS_text_icon.svg'
import blurryCoin1 from '../../../../assets/icon/blurryCoin1.png'
import whiteLogo from '../../../../assets/logo/naos_logo_white.svg'
import LauchAppButton from '../../../../components/button/LauchAppButton'
import { BackgroundImg, Content, SectionStyle, TitleIcon } from './styled'
import MediaList from '../../../../components/mediaList'

const ANIMATION_PROGRESS_LIMIT = 1500

const TEXT_DISAPPEAR_S = 1
const TEXT_DISAPPEAR_E = 400

const COIN_MOVE_S = 50
const COIN_MOVE_E = 450

const COIN_MOVE2_S = 50
const COIN_MOVE2_E = 500

const LOGO_IN_S = 200
const LOGO_IN_E = 800

const BLURRY_ALL_S = 600
const BLURRY_ALL_E = 800

/**
 * let scrolling progress translate to animation progress
 */
function getAnimationProgress(currentProgress: number, limitAmount: number): number {
  const progress = currentProgress > limitAmount ? limitAmount : currentProgress

  return (progress / limitAmount) * ANIMATION_PROGRESS_LIMIT
}

const Intro = ({ progress, limitAmount }: { progress: number; limitAmount: number }) => {
  const animationProgress = useMemo(() => {
    return getAnimationProgress(progress, limitAmount)
  }, [progress])

  const getObjectAnimationProgress = useCallback(
    (animationProgress: number, startAmount: number, endAmount: number) => {
      if (animationProgress <= startAmount) {
        return 0
      } else if (animationProgress > startAmount && animationProgress < endAmount) {
        return (animationProgress - startAmount) / (endAmount - startAmount)
      }
      return 1
    },
    []
  )

  const textDisappearProgress = getObjectAnimationProgress(animationProgress, TEXT_DISAPPEAR_S, TEXT_DISAPPEAR_E)
  // const coinMoveProgress = getObjectAnimationProgress(animationProgress, COIN_MOVE_S, COIN_MOVE_E)
  // const coin2MoveProgress = getObjectAnimationProgress(animationProgress, COIN_MOVE2_S, COIN_MOVE2_E)
  // const logoInProgress = getObjectAnimationProgress(animationProgress, LOGO_IN_S, LOGO_IN_E)
  // const blurryAllProgress = getObjectAnimationProgress(animationProgress, BLURRY_ALL_S, BLURRY_ALL_E)

  const coinMoveProgress = getObjectAnimationProgress(animationProgress, COIN_MOVE_S, COIN_MOVE_E)
  const coin2MoveProgress = getObjectAnimationProgress(animationProgress, COIN_MOVE2_S, COIN_MOVE2_E)
  const logoInProgress = getObjectAnimationProgress(animationProgress, LOGO_IN_S, LOGO_IN_E)
  const blurryAllProgress = getObjectAnimationProgress(animationProgress, BLURRY_ALL_S, BLURRY_ALL_E)

  return (
    <SectionStyle>
      <div className="veiw-block" style={{ opacity: 1 - blurryAllProgress }}>
        <div className="coinIconGrop">
          {useMemo(() => {
            return (
              <div className="text-block" style={{ opacity: 1 - textDisappearProgress }}>
                <TitleIcon alt="" src={textIcon} />
                <Content>
                  <Trans>
                    Bring real world assets on-chain
                    <br /> and re-define yield in DeFi
                  </Trans>
                </Content>

                <LauchAppButton style={{ zIndex: 5, margin: '20px' }} />

                <img className="blurrcoin-1" alt="" src={coinIcon} />
                <img className="blurrcoin-2" alt="" src={blurryCoin1} />
              </div>
            )
          }, [textDisappearProgress])}

          {useMemo(
            () => (
              <img
                style={{
                  opacity: logoInProgress * 1.5,
                  transform: `
                    perspective(500px)
                    translate3d( 0,0, ${Math.pow(logoInProgress, 2) * 400}px)`,
                  filter: `blur(${Math.abs(4 - logoInProgress * 5)}px) drop-shadow(0px 0px 24px #FFFFFF)`,
                }}
                className="white-naos-icon"
                alt=""
                src={whiteLogo}
              />
            ),
            [logoInProgress]
          )}

          {useMemo(
            () => (
              <img
                style={{
                  transform: `
                    scaleX(-1) 
                    perspective(500px)
                    translate3d(-${Math.pow(coinMoveProgress, 2) * 150}px, 
                    ${Math.pow(coinMoveProgress, 2) * 100}px, 
                    ${Math.pow(coinMoveProgress, 2) * 450}px)`,
                  filter: `blur(${coinMoveProgress * 4}px)`,
                }}
                className="coin-icon-1"
                alt=""
                src={coinIcon}
              />
            ),
            [coinMoveProgress]
          )}

          {useMemo(
            () => (
              <img
                style={{
                  transform: `
                    perspective(500px)
                    translate3d(-${Math.pow(coin2MoveProgress, 3) * 200}px, 
                    ${Math.pow(coin2MoveProgress, 3) * 90}px, 
                    ${Math.pow(coin2MoveProgress, 3) * 400}px)`,
                  filter: `blur(${coin2MoveProgress * 2}px)`,
                }}
                className="coin-icon-2"
                alt=""
                src={coinIcon}
              />
            ),
            [coin2MoveProgress]
          )}
        </div>

        <MediaList
          style={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'fixed',
            right: '30px',
            bottom: '15px',
            zIndex: 1,
          }}
          iconStyle={{
            margin: '14px 8px',
          }}
        />
        <BackgroundImg img={background} />
      </div>
    </SectionStyle>
  )
}

export default Intro
