import { Button, Theme } from '@mui/material'
import { styled } from '@mui/system'
import { HEADER_STYLE_MODE } from '../../state/slice/headerReducer'

export const HeaderStyle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mode',
})<{ theme?: Theme; mode?: HEADER_STYLE_MODE }>(({ theme, mode }) => ({
  marginTop: '30px',
  height: '75px',
  position: 'absolute',
  zIndex: 2,
  width: '100vw',
  display: 'flex',
  transition: theme.transitions.create(['margin-top'], {
    easing: theme.transitions.easing.sharp,
    duration: 200,
  }),
  ...(mode === HEADER_STYLE_MODE.CONTENT && {
    marginTop: 0,
    background: 'linear-gradient(90deg, #13207b 1%, #361f6a 100%)',
  }),
}))

export const MobileHeaderStyle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mode',
})<{ theme?: Theme; mode?: HEADER_STYLE_MODE }>(({ theme, mode }) => ({
  height: '75px',
  position: 'absolute',
  top: 0,
  zIndex: 1,
  width: '100vw',
  display: 'flex',
  justifyContent: 'space-between',
  transition: theme.transitions.create(['background'], {
    easing: theme.transitions.easing.sharp,
    duration: 200,
  }),
  ...(mode === HEADER_STYLE_MODE.CONTENT && {
    marginTop: 0,
    background: 'linear-gradient(90deg, #13207b 1%, #361f6a 100%)',
  }),
}))

export const MenuButton = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20px',
  '&:hover': {
    opacity: '0.4',
  },
}))

export const MobileMenu = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ theme?: Theme; open?: boolean }>(({ theme, open }) => ({
  background: 'rgba(255, 255, 255, 0.29)',
  position: 'absolute',
  top: '75px ',
  width: '100vw',
  zIndex: 8,
  height: 0,
  overflow: 'hidden',

  '.inner-block': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: '50px 50px 0 50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  transition: theme.transitions.create(['height'], {
    easing: theme.transitions.easing.sharp,
    duration: 200,
  }),

  ...(open && {
    height: 450,
  }),
}))

export const MobileMenuMask = styled('div')({
  backdropFilter: 'blur(5px)',
  position: 'absolute',
  top: 75,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 7,
})

export const Divider = styled('div')({
  height: '1px',
  backgroundColor: '#FFFFFF',
  margin: '20px auto',
  width: '100%',
})

export const IconBlock = styled('div')({
  flex: 1,
  display: 'flex',
})

export const ButtonBlock = styled('div')({
  display: 'flex',
})

export const HeaderButton = styled(Button)({
  backgroundColor: 'inherit',
  textTransform: 'none',
  whiteSpace: 'nowrap',
  padding: '6px 20px',
  '&:hover': {
    color: '#AAAAAA',
  },
})

export const MobileHeaderButton = styled(Button)({
  backgroundColor: 'inherit',
  textTransform: 'none',
  whiteSpace: 'nowrap',
  padding: '10px 10px',
  fontSize: '16px',
  '&:hover': {
    color: '#AAAAAA',
  },
})

export const LaunchButton = styled(Button)({
  backgroundColor: '#FFFFFF',
  color: '#979595',
  textTransform: 'none',
  border: `1px solid #FFFFFF`,
  whiteSpace: 'nowrap',
  padding: `0 20px`,
  margin: `22px 50px 22px 20px`,
  minWidth: '80px',
  '&:hover': {
    backgroundColor: 'inherit',
    border: `1px solid #AAAAAA`,
    color: '#AAAAAA',
  },
})

export const MobileLaunchButton = styled(Button)({
  backgroundColor: '#FFFFFF',
  color: '#55567B',
  textTransform: 'none',
  border: `1px solid #FFFFFF`,
  whiteSpace: 'nowrap',
  padding: `5px 20px`,
  margin: '0 auto',
  fontWeight: 700,
  '&:hover': {
    backgroundColor: 'inherit',
    border: `1px solid #AAAAAA`,
    color: '#AAAAAA',
  },
})

export const LogoImage = styled('img')({
  marginLeft: '28px',
  width: 160,
  cursor: 'pointer',
})
