import { styled } from '@mui/system'
import { Typography, useMediaQuery, useTheme, Theme } from '@mui/material'

import TeamCarousel from './carousel/TeamCarousel'
import { SCREEN_TYPE } from '../../../config/screenConfig'

import earthIcon from '../../../assets/background/earth.png'
import { Trans } from '@lingui/macro'

const SectionStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  minHeight: '800px',
  padding: '50px 50px 30px 50px',
  color: theme.palette.text.primary,

  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    padding: '80px 20px 50px 20px',
    minHeight: '300px',
  },
}))

const BackgroundEatchIcon = styled('img')(({ theme }) => ({
  width: '55%',

  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    width: '330px',
  },
}))

const Background = styled('div')(({ theme }) => ({
  margin: 'auto',
  maxWidth: '1300px',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    flexWrap: 'wrap',
  },
}))

const TextBlock = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  width: '45%',
  marginLeft: '50px',

  transition: theme.transitions.create(['all'], {
    easing: theme.transitions.easing.sharp,
    duration: 500,
  }),

  '&:hover': {
    transform: 'scale(1.1)',
  },

  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    width: 'auto',
    margin: '10px',
  },
}))

// const MobileIcon = styled('img')({})

const About = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(SCREEN_TYPE.MOBILE))

  return (
    <SectionStyle>
      {/* {isMobile ? <Icon alt="" src={explanatoryIconMobile} /> : <Icon alt="" src={explanatoryIcon} />} */}
      <Background>
        <BackgroundEatchIcon alt="" src={earthIcon} />
        <TextBlock>
          <Typography style={isMobile ? { fontSize: '18px' } : { fontSize: '30px' }} variant={'h5'}>
            <Trans>
              NAOS Finance is a decentralized lending protocol for real businesses accessing DeFi liquidity. Expanding
              the use cases for crypto lending, and providing a more efficient source of funding for businesses
              worldwide
            </Trans>
          </Typography>
        </TextBlock>
      </Background>
    </SectionStyle>
  )
}

export default About
