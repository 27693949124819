import { Trans } from '@lingui/macro'
import { Button } from '@mui/material'
import { styled } from '@mui/system'

const LaunchAppButtonStyle = styled(Button)(({ style }: { style?: React.CSSProperties }) => ({
  display: 'flex',
  color: '#FFFFFF',
  textTransform: 'none',
  whiteSpace: 'nowrap',
  fontSize: '16px',
  fontWeight: 500,
  padding: `12px 32px`,
  margin: '20px auto',
  borderRadius: `6px`,
  border: `1px solid #FFFFFF`,
  boxShadow: `0px 0px 5px 0px #FFFFFF`,

  '&:hover': {
    border: `1px solid #AAAAAA`,
    backgroundColor: '#FFFFFF',
    boxShadow: `0px 0px 12px #FFFFFF`,
    color: '#AAAAAA',
  },
  ...style,
}))

const LauchAppButton = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <LaunchAppButtonStyle
      style={style}
      onClick={() => window.open('https://app.naos.finance', '_blank', 'noopener noreferrer')}
    >
      <Trans>Launch app</Trans>
      <div className="border-effect" />
    </LaunchAppButtonStyle>
  )
}

export default LauchAppButton
