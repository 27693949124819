import { styled } from '@mui/system'
import { Typography, useMediaQuery, useTheme } from '@mui/material'

import TeamCarousel from './carousel/TeamCarousel'
import { SCREEN_TYPE } from '../../../config/screenConfig'

import explanatoryIcon from '../../../assets/icon/explanatoryDiagram.png'
import explanatoryIconMobile from '../../../assets/icon/explanatoryDiagram_m.png'

const SectionStyle = styled('div')(({ theme }) => ({
  minHeight: '300px',
  padding: '20px 0px 20px 0px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.primary,
  flexDirection: 'column',
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    padding: '0px 0px 10px 0px',
    minHeight: '300px',
  },
}))

const Icon = styled('img')({
  width: '100%',
  maxWidth: '1400px',
  marginTop: '50px',
})

// const MobileIcon = styled('img')({})

const ExplanatoryDiagram = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(SCREEN_TYPE.MOBILE))

  return (
    <SectionStyle>
      {isMobile ? <Icon alt="" src={explanatoryIconMobile} /> : <Icon alt="" src={explanatoryIcon} />}
    </SectionStyle>
  )
}

export default ExplanatoryDiagram
