import { styled } from '@mui/system'

import DiscordIcon from '../../assets/icon/icon_media_discord.png'
import TelegramIcon from '../../assets/icon/icon_media_telegram.png'
import TwitterIcon from '../../assets/icon/icon_media_twitter.png'
import MediumIcon from '../../assets/icon/icon_media_medium.png'
import WechatIcon from '../../assets/icon/icon_media_wechat.png'
import WechatQrcode from '../../assets/icon/img_qrcode_wechat.png'
import GithubIcon from '../../assets/icon/icon_media_github.png'
import GitbookIcon from '../../assets/icon/icon_media_gitbook.png'
import RedditIcon from '../../assets/icon/icon_media_reddit.png'
import { Tooltip } from '@mui/material'

const mediaIconList = [
  {
    name: 'Medium',
    link: 'https://medium.com/naos-finance',
    icon: MediumIcon,
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/naos_finance',
    icon: TwitterIcon,
  },
  {
    name: 'Telegram',
    link: 'https://t.me/naos_finance',
    icon: TelegramIcon,
  },
  {
    name: 'Discord',
    link: 'https://discord.gg/rRaPwCVTaA',
    icon: DiscordIcon,
  },
  // {
  //   name: 'Reddit',
  //   link: 'https://www.reddit.com/r/NAOS_Finance/',
  //   icon: RedditIcon,
  // },

  // {
  //   name: 'GitbookIcon',
  //   link: 'https://naosfinance.gitbook.io/naos-finance/',
  //   icon: GitbookIcon,
  // },
  // {
  //   name: 'Github',
  //   link: 'https://github.com/NAOS-Finance',
  //   icon: GithubIcon,
  // },
]

const ListStyle = styled('div')(({ style }) => ({
  display: 'flex',
  ...style,
}))

const Icon = styled('img')(({ style }) => ({
  width: '20px',
  margin: '5px',
  cursor: 'pointer',
  '&:hover': {
    opacity: '0.4',
  },
  ...style,
}))

const MediaList = ({ style, iconStyle }: { style?: React.CSSProperties; iconStyle?: React.CSSProperties }) => {
  return (
    <ListStyle style={style}>
      {mediaIconList.map((item, index) => (
        <Icon
          onClick={() => window.open(item.link, '_blank', 'noopener noreferrer')}
          key={`media-icon-${index}`}
          alt=""
          src={item.icon}
          style={iconStyle}
        />
      ))}
      <Tooltip
        title={<img alt="" width="120" height="120" src={WechatQrcode} />}
        placeholder="top"
        enterTouchDelay={0}
        leaveTouchDelay={3000}
      >
        <Icon src={WechatIcon} />
      </Tooltip>
    </ListStyle>
  )
}

export default MediaList
