import { styled } from '@mui/system'
import { Link } from 'react-router-dom'
import { Tooltip } from '@mui/material'

import MediaList from '../mediaList'
import { SCREEN_TYPE } from '../../config/screenConfig'

import WechatQrcode from '../../assets/icon/img_qrcode_wechat.png'
import { Trans } from '@lingui/macro'

const SectionStyle = styled('div')(({ theme }) => ({
  minHeight: '200px',
  padding: '20px 50px 20px 50px',
  color: theme.palette.text.primary,
  fontFamily: 'Work Sans',

  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    padding: '20px 20px 10px 20px',
  },
}))

const Divider = styled('div')({
  height: '1px',
  backgroundColor: '#FFFFFF',
  margin: '50px 0',
})

const FooterColumn = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  minWidth: '130px',
  padding: '0 20px',

  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    padding: '10px 10px',
    minWidth: '100px',
  },
}))

const FooterTitle = styled('div')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '20px',
  paddingBottom: '20px',

  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    fontSize: '18px',
  },
}))

const FooterOption = styled('div')(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  cursor: 'pointer',
  color: theme.palette.text.primary,
  paddingBottom: '10px',
  textDecoration: 'none',
  ':hover': {
    color: '#AAAAAA',
  },
  ':visited': {
    textDecoration: 'none',
  },

  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    fontSize: '12px',
  },
}))

const RounterOption = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  ':hover': {
    color: '#AAAAAA',
  },
  ':visited': {
    textDecoration: 'none',
  },
}))

const CopyrightBar = styled('div')(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  color: ' #2563EB',
  textAlign: 'right',
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    fontSize: '12px',
  },
}))

const Footer = () => {
  return (
    <SectionStyle>
      <Divider />

      <FooterColumn>
        <FooterTitle>
          <Trans>About</Trans>
        </FooterTitle>
        <FooterOption>
          <RounterOption to="/terms">
            <Trans>Terms</Trans>
          </RounterOption>
        </FooterOption>
        <FooterOption>
          <RounterOption to="/privacy">
            <Trans>Privacy</Trans>
          </RounterOption>
        </FooterOption>
        <FooterOption
          onClick={() => window.open('https://angel.co/company/naos-finance-3', '_blank', 'noopener noreferrer')}
        >
          <Trans>Career</Trans>
        </FooterOption>
      </FooterColumn>

      <FooterColumn>
        <FooterTitle>
          <Trans>Document</Trans>
        </FooterTitle>

        <FooterOption
          onClick={() => window.open('https://naosfinance.gitbook.io/naos-finance/', '_blank', 'noopener noreferrer')}
        >
          <Trans>Documentation</Trans>
        </FooterOption>
        <FooterOption onClick={() => window.open('https://github.com/NAOS-Finance', '_blank', 'noopener noreferrer')}>
          <Trans>Github</Trans>
        </FooterOption>
        <FooterOption onClick={() => window.open('https://immunefi.com/bounty/naos/', '_blank', 'noopener noreferrer')}>
          <Trans>Bug Bounty</Trans>
        </FooterOption>
        <FooterOption>
          <RounterOption to="/brand-assets">
            <Trans>Brand Assets</Trans>
          </RounterOption>
        </FooterOption>
      </FooterColumn>

      <FooterColumn>
        <FooterTitle>
          <Trans>Community</Trans>
        </FooterTitle>

        <FooterOption onClick={() => window.open('https://twitter.com/naos_finance', '_blank', 'noopener noreferrer')}>
          Twitter
        </FooterOption>
        <FooterOption onClick={() => window.open('https://t.me/naos_finance', '_blank', 'noopener noreferrer')}>
          Telegram
        </FooterOption>
        <FooterOption
          onClick={() => window.open('https://discord.com/invite/rRaPwCVTaA', '_blank', 'noopener noreferrer')}
        >
          Discord
        </FooterOption>
        <Tooltip
          title={<img alt="" width="120" height="120" src={WechatQrcode} />}
          placeholder="top"
          enterTouchDelay={0}
          leaveTouchDelay={3000}
        >
          <FooterOption>Wechat</FooterOption>
        </Tooltip>
        <FooterOption
          onClick={() => window.open('https://www.reddit.com/r/NAOS_Finance/', '_blank', 'noopener noreferrer')}
        >
          Reddit
        </FooterOption>
      </FooterColumn>

      <FooterColumn>
        <FooterTitle>
          <Trans>Find us</Trans>
        </FooterTitle>
        <FooterOption onClick={() => window.open('https://medium.com/naos-finance', '_blank', 'noopener noreferrer')}>
          <Trans>Medium</Trans>
        </FooterOption>
        <FooterOption
          onClick={() =>
            window.open('https://www.youtube.com/channel/UCPu4TKw6uQaKFuXAUtzdtMg', '_blank', 'noopener noreferrer')
          }
        >
          <Trans>Youtube</Trans>
        </FooterOption>
        <FooterOption
          onClick={() =>
            window.open('https://www.coingecko.com/en/coins/naos-finance', '_blank', 'noopener noreferrer')
          }
        >
          <Trans>Coingecko</Trans>
        </FooterOption>
        <FooterOption
          onClick={() =>
            window.open('https://coinmarketcap.com/currencies/naos-finance/', '_blank', 'noopener noreferrer')
          }
        >
          <Trans>CoinMarketCap</Trans>
        </FooterOption>
        <FooterOption
          onClick={() => window.open('https://defillama.com/protocol/naos-finance', '_blank', 'noopener noreferrer')}
        >
          DefiLlama
        </FooterOption>
      </FooterColumn>

      <MediaList
        style={{
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
        iconStyle={{
          margin: '14px 8px',
        }}
      />

      <CopyrightBar>
        <Trans>Copyright © 2022 NAOS. All rights reserved.</Trans>
      </CopyrightBar>
    </SectionStyle>
  )
}

export default Footer
