import { createStyles, makeStyles, Typography } from '@mui/material'
import styled from 'styled-components'
import Element01 from '../../assets/logo/element_01.png'
import Element02 from '../../assets/logo/element_02.png'
import Element03 from '../../assets/logo/element_03.png'
import Element04 from '../../assets/logo/element_04.png'
import Element05 from '../../assets/logo/element_05.png'
import Element06 from '../../assets/logo/element_06.png'
import Logo1Blue from '../../assets/logo/logo1_rgb.png'
import Logo2Blue from '../../assets/logo/logo2_rgb.png'
import Logo3Blue from '../../assets/logo/logo3_rgb.png'
import Logo1Black from '../../assets/logo/logo1_black_rgb.png'
import Logo1White from '../../assets/logo/logo1_white_rgb.png'
import { WapperStyle } from '../../components/wrapper'
import Footer from '../../components/footer'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { HEADER_STYLE_MODE, setHeaderStyleMode } from '../../state/slice/headerReducer'
import { SCREEN_TYPE } from '../../config/screenConfig'

const Container = styled.div`
  color: #ffffff;
  padding: 100px 68px 0;
  text-align: left;
  @media (max-width: ${SCREEN_TYPE.MOBILE}px) {
    padding: 80px 20px 0;
  }
`

const InnerCotainer = styled.div`
  color: black;
  background-color: #fff;
  border-radius: 15px;
  margin-top: 32px;

  @media (max-width: ${SCREEN_TYPE.MOBILE}px) {
    //margin-top: 80px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 40px 0;

  @media (max-width: ${SCREEN_TYPE.MOBILE}px) {
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 60px;

  img {
    height: fit-content;
    display: flex;
    flex-basis: fit-content;
  }

  @media (max-width: ${SCREEN_TYPE.MOBILE}px) {
    padding: 0;
  }
`

const ColorUsageCard = styled(Card)`
  width: 30%;
  @media (max-width: ${SCREEN_TYPE.MOBILE}px) {
    width: 100%;
  }
`

const CardContainer = styled.div`
  color: black;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    height: auto;
    display: flex;
    flex-basis: fit-content;
  }

  div {
    width: 100%;
  }

  a {
    color: white;
    background-color: #267bfb;
    border-radius: 30px;
    padding: 5px 10px;
    font-size: small;
    margin: 0 2px;
    cursor: pointer;
    text-decoration: none;
  }
`

const Section = styled.div`
  text-align: left;
  margin-top: 48px;
`

const BrandAssets = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setHeaderStyleMode(HEADER_STYLE_MODE.CONTENT))
  }, [])

  return (
    <WapperStyle>
      <Container>
        <Section>
          <Typography variant="h5">LOGO</Typography>
          <Typography style={{ marginTop: 8 }}>
            The NAOS logo displayed in the official colors and usage patterns. Please keep the logo clearly visible.
          </Typography>
          <InnerCotainer>
            <Row
              style={{
                justifyContent: 'space-between',
              }}
            >
              <Card
                style={{
                  width: '100%',
                }}
              >
                <img
                  style={{
                    width: '100%',
                    height: 'auto',
                  }}
                  src={Element01}
                />
              </Card>
            </Row>
          </InnerCotainer>
        </Section>

        <Section>
          <Typography variant="h5">COLOR USAGE</Typography>
          <InnerCotainer>
            <Row
              style={{
                justifyContent: 'center',
              }}
            >
              <ColorUsageCard>
                <CardContainer>
                  <div>
                    <img src={Element03} />
                  </div>
                  <Row
                    style={{
                      padding: '0',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        padding: '5px',
                      }}
                    >
                      <Typography
                        style={{
                          color: '#2c1f9b',
                        }}
                      >
                        Primary Usage
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '5px',
                      }}
                    >
                      <a download href="/logo_rgb.ai">
                        AI
                      </a>
                      <a download href={Logo1Blue}>
                        PNG
                      </a>
                    </div>
                  </Row>
                </CardContainer>
              </ColorUsageCard>
              <ColorUsageCard>
                <CardContainer>
                  <div>
                    <img src={Element04} />
                  </div>
                  <Row
                    style={{
                      padding: '0',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        padding: '5px',
                      }}
                    >
                      <Typography
                        style={{
                          color: '#2c1f9b',
                        }}
                      >
                        Discretionary Usage
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '5px',
                      }}
                    >
                      <a download href="/logo_rgb.ai">
                        AI
                      </a>
                      <a download href={Logo1Black}>
                        PNG
                      </a>
                    </div>
                  </Row>
                </CardContainer>
              </ColorUsageCard>
            </Row>
            <Row
              style={{
                justifyContent: 'center',
              }}
            >
              <ColorUsageCard>
                <CardContainer>
                  <div>
                    <img src={Element05} />
                  </div>
                  <Row
                    style={{
                      padding: '0',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        padding: '5px',
                      }}
                    >
                      <Typography
                        style={{
                          color: '#2c1f9b',
                        }}
                      >
                        Discretionary Usage
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '5px',
                      }}
                    >
                      <a download href="/logo_rgb.ai">
                        AI
                      </a>
                      <a download href={Logo1White}>
                        PNG
                      </a>
                    </div>
                  </Row>
                </CardContainer>
              </ColorUsageCard>
              <ColorUsageCard>
                <CardContainer>
                  <div>
                    <img src={Element06} />
                  </div>
                  <Row
                    style={{
                      padding: '0',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        padding: '5px',
                      }}
                    >
                      <Typography
                        style={{
                          color: '#2c1f9b',
                        }}
                      >
                        Discretionary Usage
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '5px',
                      }}
                    >
                      <a download href="/logo_rgb.ai">
                        AI
                      </a>
                      <a download href={Logo1White}>
                        PNG
                      </a>
                    </div>
                  </Row>
                </CardContainer>
              </ColorUsageCard>
            </Row>
          </InnerCotainer>
        </Section>

        <Section
          style={{
            marginBottom: '48px',
          }}
        >
          <Typography variant="h5">PLEASE DON'T</Typography>
          <Typography style={{ marginTop: 8 }}>Please don't alter the logo in any way.</Typography>
          <InnerCotainer>
            <Row
              style={{
                justifyContent: 'space-between',
              }}
            >
              <Card
                style={{
                  width: '100%',
                }}
              >
                <img
                  style={{
                    width: '100%',
                    height: 'auto',
                  }}
                  src={Element02}
                />
              </Card>
            </Row>
          </InnerCotainer>
        </Section>
      </Container>
      <Footer />
    </WapperStyle>
  )
}

export default BrandAssets
