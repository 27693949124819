import { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useMediaQuery, useTheme } from '@mui/material'

import partnerLogo1 from '../../../../assets/partners/maker.png'
import partnerLogo2 from '../../../../assets/partners/chainlink.png'
import partnerLogo3 from '../../../../assets/partners/solv.png'
import partnerLogo4 from '../../../../assets/partners/dforce.png'
import partnerLogo5 from '../../../../assets/partners/corl.png'
import partnerLogo6 from '../../../../assets/partners/tidal.png'
import partnerLogo7 from '../../../../assets/partners/iotex.png'
import partnerLogo8 from '../../../../assets/partners/ethSign.png'
import { SCREEN_TYPE } from '../../../../config/screenConfig'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'

const memberList = [
  {
    name: 'maker',
    img: partnerLogo1,
  },
  {
    name: 'chainlink',
    img: partnerLogo2,
  },
  {
    name: 'solv',
    img: partnerLogo3,
  },
  {
    name: 'dforce',
    img: partnerLogo4,
  },
  {
    name: 'corl',
    img: partnerLogo5,
  },
  {
    name: 'tidal',
    img: partnerLogo6,
  },
  {
    name: 'iotex',
    img: partnerLogo7,
  },
  {
    name: 'ethSign',
    img: partnerLogo8,
  },
]

const INTERVAL_AMOUNT = 250
const MEMBER_CARD_WIDTH = 180
const ANIMATION_SECOND = 3
const ANIMATION_DURING_SECOND = 0.5

const MOBILE_INTERVAL_AMOUNT = 170
const MOBILE_MEMBER_CARD_WIDTH = 120
const MOBILE_ANIMATION_SECOND = 3

const SlideShowStyle = styled('div')`
  padding: 90px 30px;

  @media (max-width: ${SCREEN_TYPE.MOBILE}px) {
    padding: 50px 10px;
  }
`

const VeiwStyle = styled.div`
  margin: 0 auto;
  max-width: 750px;
  height: 130px;
  overflow: hidden;
  position: relative;

  @media (max-width: ${SCREEN_TYPE.MOBILE}px) {
    padding: 30px 20px;
    height: 100px;
  }
`

const MemberCardStyle = styled.div<{
  width: number
  baseCoordinate: number
  sec: number
  position: number
}>`
  position: absolute;
  display: flex;
  height: 100px;
  flex-direction: column;
  justify-content: center;
  width: ${({ width }) => width}px;
  left: ${({ baseCoordinate }) => -baseCoordinate}px;
  transition: transform ${({ sec }) => sec}s ease-in-out;
  transform: ${({ position }) => `translate(${position}px)`};

  ${({ position }) => (position > 1250 || position < 250 ? 'visibility:hidden;' : '')}
`

const Icon = styled('img')`
  width: ${MEMBER_CARD_WIDTH}px;
  @media (max-width: ${SCREEN_TYPE.MOBILE}px) {
    width: ${MOBILE_MEMBER_CARD_WIDTH}px;
  }
`

const Button = styled('div')`
  position: absolute;
  cursor: pointer;
  bottom: 0px;
  width: 50%;
  height: 130px;
  display: flex;
  align-items: center;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }

  @media (max-width: ${SCREEN_TYPE.MOBILE}px) {
    bottom: -35px;
    height: 50px;
  }
`

const ButtonNext = styled(Button)`
  justify-content: flex-end;
  right: -60px;
  @media (max-width: ${SCREEN_TYPE.MOBILE}px) {
    right: 0px;
  }
`

const ButtonPrevious = styled(Button)`
  left: -60px;
  @media (max-width: ${SCREEN_TYPE.MOBILE}px) {
    left: 0px;
  }
`

const ButtonBar = styled('div')`
  position: relative;
  max-width: 850px;
  margin: auto;
`

const Circle = styled.div<{ enable: boolean }>`
  border: 3px solid #b0d0ff;
  border-radius: 50%;
  margin: 4px;
  opacity: 0.5;
  display: inline-block;
  ${({ enable }) => (enable ? `opacity: 1;` : '')}
`

const ProgressCircle = ({ number }) => {
  return (
    <ProgressCircleStyle>
      {memberList.map((obj, index) => (
        <Circle key={index} enable={index === number} />
      ))}
    </ProgressCircleStyle>
  )
}

const ProgressCircleStyle = styled('div')`
  text-align: center;
  margin: auto;
`

const PartnerMemberCard = ({ config, elementsPosition, width, baseCoordinate, sec }) => {
  const { name, img } = config

  return (
    <MemberCardStyle width={width} baseCoordinate={baseCoordinate} sec={sec} position={elementsPosition}>
      <Icon alt={name} src={img} />
    </MemberCardStyle>
  )
}

const PartnerSlideshow = () => {
  const [num, setNum] = useState<number>(3)
  let enable = false
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(SCREEN_TYPE.MOBILE))
  const interval = isMobile ? MOBILE_INTERVAL_AMOUNT : INTERVAL_AMOUNT
  const sec = isMobile ? MOBILE_ANIMATION_SECOND : ANIMATION_SECOND
  const baseCoordinate = isMobile ? 365 : 475

  const positions = useMemo(() => memberList.map((obj, index) => index * interval), [isMobile])

  useEffect(() => {
    const intervalID = window.setInterval(() => {
      enable = false
      setNum((pre) => (pre + 1 > memberList.length - 1 ? 0 : pre + 1))
      setTimeout(() => (enable = true), ANIMATION_DURING_SECOND * 1400)
    }, sec * 1000)
    return () => {
      window.clearInterval(intervalID)
    }
  }, [isMobile])

  useEffect(() => {
    enable = false
    setTimeout(() => {
      enable = true
    }, ANIMATION_DURING_SECOND * 1400)
  }, [num])

  const handleNext = () => {
    if (!enable) return
    setNum(() => (num + 1 > memberList.length - 1 ? 0 : num + 1))
  }
  const handlePrevious = () => {
    if (!enable) return
    setNum(() => (num - 1 < 0 ? memberList.length - 1 : num - 1))
  }

  return (
    <SlideShowStyle>
      <VeiwStyle>
        {positions.map((obj, index) => (
          <PartnerMemberCard
            key={`team-memeber-${index}`}
            config={memberList[index]}
            elementsPosition={positions[index + num < positions.length ? index + num : index + num - positions.length]}
            width={MEMBER_CARD_WIDTH}
            baseCoordinate={baseCoordinate}
            sec={ANIMATION_DURING_SECOND}
          />
        ))}
      </VeiwStyle>
      <ButtonBar>
        <ButtonPrevious onClick={() => handlePrevious()}>
          <ArrowCircleLeftIcon />
        </ButtonPrevious>
        <ButtonNext onClick={() => handleNext()}>
          <ArrowCircleRightIcon />
        </ButtonNext>
      </ButtonBar>
      <ProgressCircle number={num} />
    </SlideShowStyle>
  )
}

export default PartnerSlideshow
