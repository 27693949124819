import { styled } from '@mui/system'
import { Trans } from '@lingui/macro'
import { useMediaQuery, useTheme } from '@mui/material'

import Icon1 from '../../../assets/icon/incom_Icon.png'
import Icon2 from '../../../assets/icon/sustainable_icon.png'
import Icon3 from '../../../assets/icon/asset_icon.png'
import { SCREEN_TYPE } from '../../../config/screenConfig'

const SectionStyle = styled('div')(({ theme }) => ({
  minHeight: '800px',
  padding: '50px 50px 100px 50px',
  color: theme.palette.text.primary,
  fontFamily: 'Work Sans',
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    padding: '100px 20px 20px 20px',
    minHeight: '400px',
  },
}))

const BlueBlock = styled('div')({
  margin: '30px auto',
  maxWidth: '1000px',
  borderRadius: `13px`,
  border: `1px solid rgba(85, 182, 255, 0.5)`,
  boxShadow: `0px 0px 4px 0px #FFFFFF`,
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
})

const WhiteBlock = styled(BlueBlock)({
  border: `1px solid rgba(85, 182, 255, 0.61)`,
  boxShadow: `0px 0px 4px 0px rgba(85, 182, 255, 0.61)`,
})

const TextBlock = styled('div')(({ theme }) => ({
  maxWidth: '700px',
  padding: '10px 0',
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {},
}))

const ProtocolTitle = styled('div')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '30px',
  textAlign: 'center',
  padding: '0 30px 30px 30px',
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    fontSize: '18px',
    padding: '0 10px 10px 10px',
  },
}))

const ProtocolContent = styled('div')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '18px',
  textAlign: 'center',
  padding: '0 30px',
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    fontSize: '12px',
    fontWeight: 400,
    padding: '0 10px',
  },
}))

const Icon = styled('img')(({ style }) => ({ theme }) => ({
  margin: '20px',
  ...style,
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    margin: '5px',
  },
}))

const Protocol = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(SCREEN_TYPE.MOBILE))

  return (
    <SectionStyle>
      <BlueBlock>
        <Icon alt="" src={Icon1} style={isMobile ? { width: '125px' } : { width: '185px' }} />
        <TextBlock>
          <ProtocolTitle>
            <Trans>Income Predictability</Trans>
          </ProtocolTitle>
          <ProtocolContent>
            <Trans>
              Interest rates in crypto are subject to market volatility, lock-in fixed and sustainable yields on NAOS to
              better manage your portfolios
            </Trans>
          </ProtocolContent>
        </TextBlock>
      </BlueBlock>

      <WhiteBlock>
        <TextBlock>
          <ProtocolTitle>
            <Trans>Sustainable Yield Source</Trans>
          </ProtocolTitle>
          <ProtocolContent>
            <Trans>
              NAOS provides stablecoin-based yields from income-generating real world assets, yield no longer rely on
              subsidies that may one day run out
            </Trans>
          </ProtocolContent>
        </TextBlock>
        <Icon alt="" src={Icon2} style={isMobile ? { width: '115px' } : { width: '170px' }} />
      </WhiteBlock>

      <BlueBlock>
        <Icon alt="" src={Icon3} style={isMobile ? { width: '90px' } : { width: '135px' }} />
        <TextBlock>
          <ProtocolTitle>
            <Trans>Asset Stability and Security</Trans>
          </ProtocolTitle>
          <ProtocolContent>
            <Trans>
              NAOS originates real world assets that are stable in value, there is no liquidation risk associated with
              crypto market swings
            </Trans>
          </ProtocolContent>
        </TextBlock>
      </BlueBlock>
    </SectionStyle>
  )
}

export default Protocol
