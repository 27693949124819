import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SECTION_NAME } from '../../../pages/mainPanel'

export enum HEADER_STYLE_MODE {
  DEFAULT,
  CONTENT,
}

const initialState: { option: SECTION_NAME; mode: HEADER_STYLE_MODE } = {
  option: SECTION_NAME.HOME,
  mode: HEADER_STYLE_MODE.DEFAULT,
}

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeaderOption(state, { payload }: PayloadAction<SECTION_NAME>) {
      state.option = payload
    },

    setHeaderStyleMode(state, { payload }: PayloadAction<HEADER_STYLE_MODE>) {
      state.mode = payload
    },
  },
})

// export const { option, mode } = (state) => state.header
export const selectHeader = (state) => state.header
export const { setHeaderOption, setHeaderStyleMode } = headerSlice.actions
export default headerSlice.reducer
