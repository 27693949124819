import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../../components/footer'
import { WapperStyle } from '../../components/wrapper'
import { SCREEN_TYPE } from '../../config/screenConfig'
import { HEADER_STYLE_MODE, selectHeader, setHeaderStyleMode } from '../../state/slice/headerReducer'
import Pool from './section/Pool'
import Investors from './section/Investors'
import ExplanatoryDiagram from './section/ExplanatoryDiagram'
import Intro from './section/Intro/Intro'
import Protocol from './section/Protocol'
import About from './section/About'
import styled from '@emotion/styled'
import { Video } from './section/Video'

const ANMATION_HEIGHT = 1200

export enum SECTION_NAME {
  HOME = 'HOME',
  INTRO = 'INTRO',
  POOL = 'POOL',
  TEAM = 'TEAM',
  PROTOCOL = 'PROTOCOL',
  INVESTORS = 'INVESTORS',
}

const TagBlock = styled('div')`
  height: 1px;
`

function MainPanel() {
  const headerOption = useSelector(selectHeader)?.option
  const dispatch = useDispatch()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(SCREEN_TYPE.MOBILE))

  const wapperRef = useRef<HTMLDivElement>(null)
  const introRef = useRef<HTMLDivElement>(null)
  const poolRef = useRef<HTMLDivElement>(null)
  const protocolRef = useRef<HTMLDivElement>(null)
  const investorsRef = useRef<HTMLDivElement>(null)

  const [progress, setProgress] = useState<number>(0)

  let scrollTop = 0
  let ticking = false

  const onScroll = () => {
    if (!ticking) {
      requestAnimationFrame(updateProgress)
      ticking = true
    }
  }

  const updateProgress = () => {
    scrollTop = wapperRef.current!.scrollTop

    if (scrollTop > ANMATION_HEIGHT - 200) {
      dispatch(setHeaderStyleMode(HEADER_STYLE_MODE.CONTENT))
    } else {
      dispatch(setHeaderStyleMode(HEADER_STYLE_MODE.DEFAULT))
    }

    setProgress(scrollTop)
    ticking = false
  }

  useEffect(() => {
    dispatch(setHeaderStyleMode(HEADER_STYLE_MODE.DEFAULT))
    wapperRef?.current?.addEventListener('scroll', onScroll)
    return () => {
      wapperRef?.current?.removeEventListener('scroll', onScroll)
    }
  }, [])

  useEffect(() => {
    handleHeaderClick()
  }, [headerOption])

  const handleHeaderClick = () => {
    switch (headerOption) {
      case SECTION_NAME.HOME:
        wapperRef?.current?.scrollTo({ top: 0, behavior: 'smooth' })
        break
      case SECTION_NAME.INTRO:
        // introRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
        wapperRef?.current?.scrollTo({ top: introRef?.current?.offsetTop, behavior: 'smooth' })
        break
      case SECTION_NAME.POOL:
        //poolRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
        wapperRef?.current?.scrollTo({ top: poolRef?.current?.offsetTop, behavior: 'smooth' })
        break
      case SECTION_NAME.PROTOCOL:
        // protocolRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
        wapperRef?.current?.scrollTo({ top: protocolRef?.current?.offsetTop, behavior: 'smooth' })
        break
      case SECTION_NAME.INVESTORS:
        //investorsRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
        wapperRef?.current?.scrollTo({ top: investorsRef?.current?.offsetTop, behavior: 'smooth' })
        break
    }
  }

  return (
    <>
      {progress < ANMATION_HEIGHT + 5 && <Intro progress={progress} limitAmount={ANMATION_HEIGHT} />}
      <WapperStyle ref={wapperRef}>
        {useMemo(
          () => (
            <>
              <TagBlock ref={introRef} style={{ height: ANMATION_HEIGHT }} />

              <Video />

              <TagBlock ref={poolRef} />
              <Pool />

              <TagBlock ref={protocolRef} />
              <About />
              <Protocol />
              <ExplanatoryDiagram />

              <TagBlock ref={investorsRef} />
              <Investors />

              <Footer />
            </>
          ),
          []
        )}
      </WapperStyle>
    </>
  )
}

export default MainPanel
