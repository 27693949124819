import { useEffect, useMemo, useState } from 'react'
import { OFFICIAL_WEB_API_HOST } from '../config/endPoint'

export function useStkaingPoolsTvl() {
  const [tvls, setTvls] = useState<Object | null>(null)

  useEffect(() => {
    const setData = () =>
      fetch(`${OFFICIAL_WEB_API_HOST}/v2/api/v1/ethereum/tvls`).then((res) =>
        res.json().then(({ tvls }) => {
          const val = {
            eth: tvls['eth'],
            bsc: tvls['bsc'],
          }
          setTvls(val)
        })
      )

    setData()
  }, [])

  return useMemo(() => tvls, [tvls])
}
