import { Typography, Theme } from '@mui/material'
import { styled } from '@mui/system'
import numeral from 'numeral'
import { Trans, t } from '@lingui/macro'
import { i18n } from '@lingui/core'

import LauchAppButton from '../../../components/button/LauchAppButton'
import { ADDRESS, DefaultPoolId, NET_TYPE } from '../../../config/constantsConfig'
import { SCREEN_TYPE } from '../../../config/screenConfig'
import { useStakingPoolsAprs } from '../../../hooks/useStakingPoolsAprs'
import { useStkaingPoolsTvl } from '../../../hooks/useStakingPoolsTvl'

import PoolNaos from '../../../assets/pool/NAOS.png'
import PoolNaosEth from '../../../assets/pool/NAOS_ETH.png'
import PoolnUsd3CRV from '../../../assets/pool/nUSD_3CRV.png'
import PoolNaosBnb from '../../../assets/pool/NAOS_BNB.png'
import PoolBeta from '../../../assets/pool/betaToken.svg'

interface IPoolConfig {
  net: string
  address: string
  poolID: number
}

const itemConfig: {
  name: string
  img: string
  link: string
  TVL: IPoolConfig
  APY: IPoolConfig
}[] = [
  {
    name: t`NAOS Pool`,
    img: PoolNaos,
    link: 'https://app.naos.finance/farm/naos',
    TVL: {
      net: NET_TYPE.ETH,
      address: ADDRESS.STAKINGPOOL,
      poolID: DefaultPoolId.NAOS,
    },
    APY: {
      net: NET_TYPE.ETH,
      address: ADDRESS.STAKINGPOOL,
      poolID: DefaultPoolId.NAOS,
    },
  },
  {
    name: t`NAOS/ETH LP Pool`,
    img: PoolNaosEth,
    link: 'https://app.naos.finance/farm/naos-eth-lp',
    TVL: {
      net: NET_TYPE.ETH,
      address: ADDRESS.STAKINGPOOL,
      poolID: DefaultPoolId.NAOS_ETH_LP,
    },
    APY: {
      net: NET_TYPE.ETH,
      address: ADDRESS.STAKINGPOOL,
      poolID: DefaultPoolId.NAOS_ETH_LP,
    },
  },
  {
    name: t`nUSD/3CRV Pool`,
    img: PoolnUsd3CRV,
    link: 'https://app.naos.finance/farm/nusd-3crv',
    TVL: {
      net: NET_TYPE.ETH,
      address: ADDRESS.STAKINGPOOL,
      poolID: DefaultPoolId.NUSD_3CRV,
    },
    APY: {
      net: NET_TYPE.ETH,
      address: ADDRESS.STAKINGPOOL,
      poolID: DefaultPoolId.NUSD_3CRV,
    },
  },
  {
    name: t`NAOS Boost Pool`,
    img: PoolNaos,
    link: 'https://app.naos.finance/farm/boost',
    TVL: {
      net: NET_TYPE.BSC,
      address: ADDRESS.BOOST_POOL,
      poolID: DefaultPoolId.NAOS,
    },
    APY: {
      net: NET_TYPE.BSC,
      address: ADDRESS.BOOST_POOL,
      poolID: DefaultPoolId.NAOS,
    },
  },
  {
    name: t`NAOS/BNB Pool`,
    img: PoolNaosBnb,
    link: 'https://app.naos.finance/farm/naos-bnb-lp',
    TVL: {
      net: NET_TYPE.BSC,
      address: ADDRESS.STAKINGPOOL_WITH_TRANSFER,
      poolID: DefaultPoolId.NAOS,
    },
    APY: {
      net: NET_TYPE.BSC,
      address: ADDRESS.STAKINGPOOL_WITH_TRANSFER,
      poolID: DefaultPoolId.NAOS,
    },
  },
  {
    name: t`Beta Staking Pool`,
    img: PoolBeta,
    link: 'https://app.naos.finance/farm/beta',
    TVL: {
      net: NET_TYPE.BSC,
      address: ADDRESS.STAKINGPOOL_WITH_TRANSFER,
      poolID: DefaultPoolId.NAOS_ETH_LP,
    },
    APY: {
      net: NET_TYPE.BSC,
      address: ADDRESS.STAKINGPOOL_WITH_TRANSFER,
      poolID: DefaultPoolId.NAOS_ETH_LP,
    },
  },
]

const SectionStyle = styled('div')(({ theme }) => ({
  minHeight: '800px',
  paddingTop: '200px',
  color: theme.palette.text.primary,
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    paddingTop: '120px',
    minHeight: '400px',
  },
}))

const ItemListBlockStyle = styled('div')({
  maxWidth: '1200px',
  margin: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignContent: 'space-around',
})

const ItemStyle = styled('div')(({ theme }) => ({
  margin: '16px',
  minWidth: `330px`,
  minHeight: `180px`,
  borderRadius: `13px`,
  position: 'relative',
  cursor: 'pointer',
  '& .border-effect': {
    position: 'absolute',
    borderRadius: `13px`,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    border: `1px solid rgba(85, 182, 255, 0.5)`,
    boxShadow: `inset 0px 4px 20px -74px #FFFFFF`,
    filter: `drop-shadow(0px 0px 9px #FFFFFF)`,

    '&:hover': {
      border: `1px solid #FFFFFF`,
      boxShadow: `0px 0px 10px 5px rgba(255, 255, 255, 0.39), inset 0px 4px 20px -74px #FFFFFF`,
      filter: `drop-shadow(0px 0px 9px #FFFFFF)`,
    },
  },
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    minWidth: `240px`,
    minHeight: `130px`,
    margin: '8px',
    h2: {
      fontSize: '20px',
    },
  },
}))

const TitleStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .icon': {
    maxHeight: '40px',
    width: 'auto',
    margin: '20px',
  },
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    h4: {
      fontSize: '16px',
    },
    '& .icon': {
      maxHeight: '30px',
      margin: '12px',
    },
  },
}))

const DepositeItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'style',
})<{ theme?: Theme; style?: React.CSSProperties }>(({ theme, style }) => ({
  display: 'inline-flex',
  width: '50%',
  flexDirection: 'column',
  boxSizing: 'border-box',
  padding: '10px',
  textAlign: 'center',
  ...style,

  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    h5: {
      fontSize: '12px',
    },
    h6: {
      fontSize: '16px',
    },
    '& .icon': {
      width: '65px',
      margin: '20px',
    },
  },
}))

const Item = ({ config, TVL, APY }) => {
  const { name, img, link } = config

  return (
    <ItemStyle color={'primary'} onClick={() => window.open(link, '_blank', 'noopener noreferrer')}>
      <div className="border-effect" />

      <TitleStyle>
        <img alt="" className="icon" src={img} />
        <Typography variant={'h4'}> {i18n._(name)}</Typography>
      </TitleStyle>

      <DepositeItem style={{ width: '55%', paddingRight: '0px' }}>
        <Typography variant={'h5'}>
          <Trans>TVL</Trans>
        </Typography>
        <Typography variant={'subtitle1'}>{TVL ? numeral(TVL).format('$0,0[.]00') : '--'}</Typography>
      </DepositeItem>

      <DepositeItem style={{ width: '45%', paddingRight: '20px' }}>
        <Typography variant={'h5'}>
          <Trans>APR</Trans>
        </Typography>
        <Typography variant={'subtitle1'}> {APY ? numeral(APY).format('0.00') + '%' : '--'}</Typography>
      </DepositeItem>
    </ItemStyle>
  )
}

const Pool = () => {
  const apr = useStakingPoolsAprs()
  const tvl = useStkaingPoolsTvl()
  return (
    <SectionStyle>
      <ItemListBlockStyle>
        {itemConfig.map((config, index) => (
          <Item
            key={`about-item-${index}`}
            config={config}
            APY={apr?.[config.APY.net]?.[config.APY.address]?.[config.APY.poolID]}
            TVL={tvl?.[config.TVL.net]?.[config.TVL.address]?.[config.TVL.poolID]}
          />
        ))}
      </ItemListBlockStyle>
      {/* <Typography style={{ margin: '30px', textAlign: 'center', fontFamily: 'Didot' }} variant={'h2'}>
        <Trans>Deposit now and multiply your assets today!</Trans>
      </Typography> */}
      <LauchAppButton />
    </SectionStyle>
  )
}

export default Pool
