import { Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import styled from 'styled-components'
import { HEADER_STYLE_MODE, setHeaderStyleMode } from '../../state/slice/headerReducer'
import Footer from '../../components/footer'
import { WapperStyle } from '../../components/wrapper'
import { SCREEN_TYPE } from '../../config/screenConfig'

const Container = styled.div`
  color: #ffffff;
  padding: 160px 68px 0;
  text-align: center;

  @media (max-width: ${SCREEN_TYPE.MOBILE}px) {
    padding: 120px 32px 0;
  }
`

const Intro = styled.div`
  text-align: left;
  margin-top: 40px;

  @media (min-width: ${SCREEN_TYPE.MOBILE}px) {
    margin-top: 80px;
  }
`

const Section = styled.div`
  text-align: left;
  margin-top: 48px;
`

const Content = styled(Typography)({
  marginTop: 18,
})

// const useStyles = makeStyles((theme) =>
//   createStyles({
//     marginTop: {
//       marginTop: 18,
//     },
//   })
// )

const Terms = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setHeaderStyleMode(HEADER_STYLE_MODE.CONTENT))
  }, [])

  return (
    <WapperStyle>
      <Container>
        <Typography variant="h3">NAOS Finance</Typography>
        <Typography variant="h3" style={{ marginTop: 8 }}>
          Terms of Use
        </Typography>
        <Intro>
          <Typography variant="body1">
            The website located at https://naos.finance/ (including all subdomains, all associated sites linked to
            https://naos.finance/, and any materials presented therein) (collectively, the “Site”) is operated by, and
            is the property of, [NAOS Foundation] (together with its subsidiaries and affiliates, “NAOS”, “we”, “us” or
            “our”). The Site acts as a front-end to our decentralized finance (DeFi) protocol (the “NAOS Finance
            Protocol”) built on the Ethereum blockchain.
          </Typography>
          <br />
          <Typography variant="body1">
            These terms of use (these “Terms”) set forth the terms and conditions that govern your use of the Site,
            including (without limitation) all the applications, products, services, tools, and information made
            available thereon (collectively, the “Services”).
          </Typography>
          <br />
          <Typography variant="body1">
            BY ACCESSING THE SITE AND/OR THE SERVICES, YOU ACCEPT IN THEIR ENTIRETY AND WITHOUT MODIFICATION THESE TERMS
            (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE
            RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU
            REPRESENT). YOU MAY NOT ACCESS OR USE THE SITE OR THE SERVICES OR ACCEPT THE TERMS IF YOU ARE NOT AT LEAST
            18 YEARS OLD. IF YOU DO NOT AGREE WITH THESE TERMS, YOU ARE NOT GRANTED PERMISSION TO ACCESS OR OTHERWISE
            USE THE SITE OR THE SERVICES. NAOS RESERVES THE RIGHT TO CHANGE THESE TERMS WITHOUT NOTICE AT ANY TIME.
          </Typography>
          <br />
          <Typography variant="body1">
            In addition, when using certain components or features of the Site and/or the Services, you will be subject
            to any additional terms, policies, rules or guidelines (“Policies”) applicable to the Site and/or the
            Services or such components of the Site and/or the Services that may be posted on the Site and/or the
            Services from time to time, including, without limitation, the Privacy Policy located at
            https://naos.finance/privacy. All such Policies are hereby incorporated by reference into these Terms and
            may be changed from time to time and are effective immediately upon posting such changes on the Site and/or
            the Services.
          </Typography>
        </Intro>
        <Section>
          <Typography variant="subtitle2">1. Site and Services</Typography>
          <Content variant="body1">
            1.1 Except as specifically permitted below, nothing contained in these Terms, the Site or the Services
            itself either grants or will be construed to grant to you or any third party, by implication, estoppel or
            otherwise, any title or interest in, or any license or right to use or reproduce, any content, material,
            graphic, user interface, visual interface, photograph, sound, music, artwork, image, text, software, code,
            trademark, logo or service mark and other source-identifying symbols, designs, icons, images, or other
            information, software or code contained in or available on or through the Site and/or the Services
            (collectively, “Content”), including without limitation the name or logo of NAOS and/or any other entity
            described on the Site and/or the Services. No act of downloading or otherwise copying from the Site and/or
            the Services will transfer title to any software or material to any user hereof. Any data that you transmit
            to the Site and/or the Services (including without limitation personally-identifiable information) becomes
            the property of NAOS and may be used by NAOS for any lawful purpose without restriction, subject to the
            Privacy Policy set forth below. NAOS reserves, and will enforce to the fullest extent possible, all rights
            that it may have with respect to copyright and trademark ownership of all material contained in the Site
            and/or the Services.
          </Content>
          <Content variant="body1">
            1.2 NAOS grants you a limited, non-exclusive license to display and otherwise use portions of the Site
            and/or the Services solely for your own private, non-commercial, informational purposes only, and to print
            pages from the Site and/or the Services only in connection with that use. You may not copy, store, modify,
            distribute, transmit, perform, reproduce, publish, license, create derivative works from, transfer or sell
            any Content obtained from the Site and/or the Services without prior express written permission from NAOS
            which may be withheld for any or no reason.
          </Content>
        </Section>
        <Section>
          <Typography variant="subtitle2">2. Use of Site and Services; Restrictions</Typography>
          <Content variant="body1">
            You agree not to:
            <br />
            <br />
            (a) use any “deep-link”, “page-scrape”, “robot”, “spider” or other automatic device, program, algorithm or
            methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of
            the Site, the Services or any Content, or in any way reproduce or circumvent the navigational structure or
            presentation of the Site, the Services or any Content, to obtain or attempt to obtain any materials,
            documents or information through any means not purposely made available through the Site and/or the
            Services. NAOS reserves the right to bar any such activity;
          </Content>
          <Content variant="body1">
            (b) attempt to gain unauthorized access to any portion or feature of the Site and/or the Services, or any
            other systems or networks connected to the Site and/or the Services or to any NAOS server, or to any of the
            services offered on or through the Site and/or the Services, by hacking, password “mining” or any other
            illegitimate means;
          </Content>
          <Content variant="body1">
            (c) probe, scan or test the vulnerability of the Site and/or the Services or any network connected to the
            Site and/or the Services, nor breach the security or authentication measures on the Site and/or the Services
            or any network connected to the Site and/or the Services. You may not reverse look-up, trace or seek to
            trace any information on any other user of or visitor to the Site and/or the Services, or any other customer
            of NAOS, including any NAOS account not owned by you, to its source, or exploit the Site and/or the
            Services, in any way where the purpose is to reveal any information, including but not limited to personal
            identification or information, other than your own information, as provided for by the Site and/or the
            Services;
          </Content>
          <Content variant="body1">
            (d) take any action that imposes an unreasonable or disproportionately large load on the infrastructure of
            the Site and/or the Services or NAOS’s systems or networks, or any systems or networks connected to the Site
            and/or the Services or to NAOS;
          </Content>
          <Content variant="body1">
            (e) interfere or attempt to interfere with or disrupt in any way the proper working of the Site and/or the
            Services or servers or networks connected to the Site and/or the Services, any transaction being conducted
            on the Site and/or the Services, or any other person’s use of the Site and/or the Services;
          </Content>
          <Content variant="body1">
            (f) disobey any requirements, procedures, policies or regulations of networks connected to the Site and/or
            the Services;
          </Content>
          <Content variant="body1">
            (g) forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or
            transmittal you send to NAOS on or through the Site and/or the Services or any service offered on or through
            the Site and/or the Services. You may not pretend that you are, or that you represent, someone else, or
            impersonate any other individual or entity; or
          </Content>
          <Content variant="body1">(h) use the Site, the Services or any Content:</Content>
          <Content variant="body1">
            (i) for any purpose that is in violation of any applicable laws or regulations or prohibited by these Terms,
            or to solicit, promote or further the performance of any illegal activity or other activity which infringes
            the rights of NAOS or others;
          </Content>
          <Content variant="body1">(j) solicit personal information from anyone under the age of 18;</Content>
          <Content variant="body1">
            (k) harvest or collect email addresses or other contact information of other users from the Site and/or the
            Services by electronic or other means for the purposes of sending unsolicited emails or other unsolicited
            communications; or
          </Content>
          <Content variant="body1">
            (l) advertise or offer to sell or buy any goods or services for any business purpose that is not
            specifically authorized.
          </Content>
        </Section>
        <Section>
          <Typography variant="subtitle2">3. NAOS Finance Protocol</Typography>
          <Content variant="body1">
            3.1 As part of the Site and Services, NAOS provides access to the NAOS Finance Protocol that allows lenders
            or borrowers to perform transactions, including through the use of smart contracts (“Smart Contracts”).
          </Content>
          <Content variant="body1">
            3.2 Using the NAOS Finance Protocol may require that you pay certain fees, such as gas charges on the
            Ethereum network to perform a transaction. You acknowledge and agree that NAOS has no control over any
            transactions over the NAOS Finance Protocol or the method of payment of, or any actual payments of, any such
            transactions. Accordingly, you must ensure that you have a sufficient balance of the applicable
            cryptocurrency tokens stored at your NAOS Finance Protocol-compatible wallet address to complete any
            transaction on the NAOS Finance Protocol or the Ethereum network before initiating such transaction.
          </Content>
          <Content variant="body1">
            3.3 You acknowledge that the Site, the Services (including the NAOS Finance Protocol) and your access and
            use of the Site and the Services (including the NAOS Finance Protocol) contain certain risks, including
            (without limitation) the following: (a) any Smart Contracts you interact with are entirely your own
            responsibility and liability; (b) at any time, your access to your cryptocurrency assets may be suspended or
            terminated or there may be a delay in your access or use of your cryptocurrency assets which may result in
            the cryptocurrency assets diminishing in value or you being unable to complete a Smart Contract; and (c) any
            site and/or application may be suspended or terminated for any or no reason, which may limit your access to
            your cryptocurrency assets, and you expressly agree that you assume all risks in connection with your access
            and use of the Site, the Services (including the NAOS Finance Protocol) and the Smart Contracts.
          </Content>
        </Section>
        <Section>
          <Typography variant="subtitle2">4. Accounts</Typography>
          <Content variant="body1">
            4.1 In order to use certain features of the Site and/or the Services, you must register for an account
            (“Account”) and provide certain information about yourself as prompted by the account registration form. You
            represent and warrant that: (a) all required registration information you submit is truthful and accurate;
            (b) you will maintain the accuracy of such information. You may delete your Account at any time, for any
            reason, by following the instructions on the Site and/or the Services. NAOS may suspend or terminate your
            Account in accordance with Section 8.
          </Content>
          <Content variant="body1">
            4.2 You are responsible for maintaining the confidentiality of your Account login information and are fully
            responsible for all activities that occur under your Account. You agree to immediately notify NAOS of any
            unauthorized use, or suspected unauthorized use of your Account or any other breach of security. NAOS cannot
            and will not be liable for any loss or damage arising from your failure to comply with the above
            requirements.
          </Content>
        </Section>
        <Section>
          <Typography variant="subtitle2">5. No Offer, Solicitation or Advice</Typography>
          <Content variant="body1">
            5.1 The Content is for informational purposes only and are not intended to address any particular
            requirements. We make no representation that the Site or Services (including the NAOS Finance Protocol) is
            appropriate or available for use in your location, and accessing them from locations where their content is
            illegal is prohibited.
          </Content>
          <Content variant="body1">
            5.2 EXCEPT TO THE EXTENT EXPRESSLY SPECIFIED BY NAOS IN WRITING, NOTHING ON THE SITE AND/OR THE SERVICES IS
            INTENDED TO CONSTITUTE AN OFFER, OR SOLICITATION OF AN OFFER, TO PURCHASE OR SELL ANY SECURITY, INVESTMENT,
            OTHER ASSET OR SERVICE.
          </Content>
          <Content variant="body1">
            5.3 Also, neither the Site nor the Services is intended to provide any investment, financial, legal,
            regulatory, accounting, tax or similar advice, and nothing on the Site or the Services should be construed
            as a recommendation, by NAOS or any third party, to acquire or dispose of any investment or security, or to
            engage in any investment strategy or transaction. You should consult your own investment, legal, tax and/or
            similar professionals regarding your specific situation and any specific decisions.
          </Content>
        </Section>
        <Section>
          <Typography variant="subtitle2">6. Privacy Policy; Cookies, Analytics and Traffic Data</Typography>
          <Content variant="body1">
            6.1 NAOS’ Privacy Policy applies to use of the Site and the Services (including the NAOS Finance Protocol),
            and its terms are incorporated by reference into these Terms. To view NAOS’ Privacy Policy, please visit
            https://naos.finance/privacy.
          </Content>
          <Content variant="body1">
            6.2 Cookies are small text files which are transferred from our website, applications or services and stored
            on your device. Our cookies are typically session cookies (temporary cookies that identify and track users
            within our websites, applications or services which are deleted when you close your browser or leave your
            session in the application or service) but may in the future be persistent cookies (cookies which enable our
            website to “remember” who you are and to remember your preferences within our website, applications or
            services and which will stay on your computer or device after you close your browser or leave your session
            in the application or service).
          </Content>
          <Content variant="body1">
            6.3 We may keep a record of traffic data which is logged automatically by our servers, such as your Internet
            Protocol (IP) address, device information, the website that you visited before ours and the website you
            visit after leaving our site. We also collect some site, application and service statistics such as access
            rates, page hits and page views. We are not able to identify any individual from traffic data or site
            statistics.
          </Content>
          <Content variant="body1">
            6.4 You may be able to configure your browser or our website, application or service to restrict cookies or
            block all cookies if you wish, however if you disable cookies you may find this affects your ability to use
            certain parts of our website, applications or services.
          </Content>
        </Section>
        <Section>
          <Typography variant="subtitle2">7. No Warranty</Typography>
          <Content variant="body1">
            7.1 YOUR USE OF THE SITE AND THE SERVICES (INCLUDING THE NAOS FINANCE PROTOCOL) IS AT YOUR SOLE RISK AND YOU
            ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE SITE AND/OR THE SERVICES AND ANY LINKED SITES. YOUR SOLE
            REMEDY AGAINST NAOS FOR DISSATISFACTION WITH THE SITE AND/OR THE SERVICES, THE NAOS FINANCE PROTOCOL OR THE
            CONTENT IS TO STOP USING THE SITE AND/OR THE SERVICES, THE NAOS FINANCE PROTOCOL OR THE CONTENT. THE SITE
            AND/OR THE SERVICES AND THE CONTENT ARE DELIVERED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. NAOS AND ITS
            OFFICERS, DIRECTORS, MEMBERS, PRINCIPALS, INVESTORS, AGENTS AND EMPLOYEES MAKE NO REPRESENTATIONS OR
            WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SUITABILITY OR OTHER CHARACTERISTICS OF THE INFORMATION AND
            MATERIALS CONTAINED ON OR PRESENTED THROUGH THE SITE AND/OR THE SERVICES. NAOS HEREBY FURTHER DISCLAIMS ALL
            WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING ANY WARRANTIES OF ACCURACY,
            AVAILABILITY, NON-INFRINGEMENT, MERCHANTABILITY, TITLE AND FITNESS FOR A PARTICULAR PURPOSE. NAOS ALSO
            DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH
            OR RELATED TO YOUR USE OF THE SITE AND/OR THE SERVICES AND/OR ANY NAOS SERVICES. IF APPLICABLE LAW REQUIRES
            ANY WARRANTIES WITH RESPECT TO THE SITE AND/OR THE SERVICES, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO
            NINETY (90) DAYS FROM THE DATE OF FIRST USE.
          </Content>
          <Content variant="body1">
            7.2 NAOS AND ITS OFFICERS, DIRECTORS, MEMBERS, PRINCIPALS, INVESTORS, AGENTS AND EMPLOYEES FURTHER ASSUME NO
            RESPONSIBILITY FOR, AND MAKE NO WARRANTIES THAT, FUNCTIONS CONTAINED AT THE SITE AND/OR THE SERVICES WILL BE
            UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE AND/OR THE SERVICES OR THE
            SERVER THAT MAKES IT AVAILABLE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. NAOS AND ITS OFFICERS,
            DIRECTORS, MEMBERS, PRINCIPALS, INVESTORS, AGENTS AND EMPLOYEES SHALL NOT BE LIABLE FOR ANY DAMAGES OF ANY
            TYPE OF KIND TO, VIRUSES THAT MAY INFECT, OR SERVICES REPAIRS OR CORRECTIONS THAT MUST BE PERFORMED, ON YOUR
            COMPUTER OR OTHER PROPERTY, ON ACCOUNT OF YOUR ACCESSING OR USE OF THE SITE AND/OR THE SERVICES.
          </Content>
          <Content variant="body1">
            7.3 The above disclaimer applies to any damages, liability or injuries caused by any failure of performance,
            error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus,
            communication line failure, theft or destruction of or unauthorized access to, alteration of, or use,
            whether for breach of contract, tort, negligence or any other cause of action.
          </Content>
        </Section>
        <Section>
          <Typography variant="subtitle2">8. Limitation of Liability; Indemnification</Typography>
          <Content variant="body1">
            8.1 TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL NAOS OR ITS OFFICERS, DIRECTORS, MEMBERS,
            PRINCIPALS, INVESTORS, AGENTS AND EMPLOYEES (A “NAOS PARTY”) BE LIABLE FOR ANY CLAIMS, LIABILITIES, LOSSES,
            COSTS OR DAMAGES, INCLUDING DIRECT, INDIRECT, PUNITIVE, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL
            DAMAGES, ARISING OUT OF OR IN ANY WAY CONNECTED WITH (A) THE USE OF OR INABILITY TO USE THE SITE AND/OR THE
            SERVICES (INCLUDING THE NAOS FINANCE PROTOCOL) OR WITH ANY DELAY IN USING THE SITE AND/OR THE SERVICES
            (INCLUDING THE NAOS FINANCE PROTOCOL), INCLUDING BUT NOT LIMITED TO LOSS OF REVENUE, TRADING, PROFITS,
            ANTICIPATED PROFITS, BUSINESS, SAVINGS, GOODWILL, USE, OPPORTUNITY OR DATA; (B) THE CONTENT OR ANY
            INFORMATION AND MATERIALS OBTAINED THROUGH THE SITE AND/OR THE SERVICES; OR (C) OTHERWISE ARISING OUT OF THE
            ACCESS AND USE OF THE SITE AND/OR THE SERVICES (INCLUDING THE NAOS FINANCE PROTOCOL); IN ANY CASE WHETHER
            BASED ON THEORIES ARISING IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE. SUCH LIMITATIONS APPLY EVEN IF
            NAOS OR ANY OF ITS OFFICERS, DIRECTORS, MEMBERS, PRINCIPALS, INVESTORS, AGENTS OR EMPLOYEES HAVE BEEN
            ADVISED OF THE POSSIBILITY OF DAMAGES OR COULD HAVE FORESEEN THE DAMAGES. FURTHER, NAOS WILL NOT BE
            RESPONSIBLE FOR ANY FAILURE TO COMPLY, BY YOU OR ANY THIRD PARTY, WITH THESE TERMS OF USE OR WITH APPLICABLE
            LAWS OR REGULATIONS. IF FOR ANY REASON THE DISCLAIMERS OF WARRANTIES OR LIMITATIONS OF LIABILITY SET FORTH
            IN THIS SECTION 8 AND THE PRECEDING SECTION 7 IS/ARE INAPPLICABLE OR UNENFORCEABLE FOR ANY REASON, THEN
            NAOS’ MAXIMUM LIABILITY FOR ANY TYPE OF DAMAGES HEREUNDER SHALL BE LIMITED TO THE LESSER OF THE TOTAL FEES
            PAID BY YOU TO NAOS DURING THE SIX (6) MONTHS PRECEDING THE EVENT GIVING RISE TO THE LIABILITY AND $100.
          </Content>
          <Content variant="body1">
            8.2 These limitations of liability will apply regardless of: (a) any negligence or gross negligence of NAOS
            or any of its officers, directors, members, principals, investors, agents and employees or (b) whether the
            liability arises in negligence, gross negligence, strict liability, contract, tort (including negligence or
            gross negligence) or any other theory of legal liability; and will remain in effect even if any remedy fails
            of its essential purpose.
          </Content>
          <Content variant="body1">
            8.3 You hereby agree to indemnify to the fullest extent, defend and hold NAOS and its officers, directors,
            members, principals, investors, agents and employees harmless from and against any and all liability costs
            incurred by these parties in connection with any claim or demand due to or arising out of: (a) your use of
            the Site and/or the Services, (b) your breach of these Terms or any of the representations, warranties and
            covenants made by you herein, or (c) your violation of applicable laws or regulations, including, without
            limitation, attorneys' fees and costs. You shall cooperate as fully as reasonably required or requested in
            the defense of any claim. NAOS reserves the right, at its own expense, to assume the exclusive defense and
            control of any matter otherwise subject to indemnification by you and you shall not in any event settle any
            matter without the written consent of NAOS.
          </Content>
          <Content variant="body1">
            8.4 If you are a California resident, you waive California Civil Code Section 1542, which says: “A general
            release does not extend to claims which the creditor does not know or suspect to exist in his favor at the
            time of executing the release, which if known by him must have materially affected his settlement with the
            debtor.” If you are a resident of another jurisdiction, you waive any comparable statute or doctrine.
          </Content>
        </Section>
        <Section>
          <Typography variant="subtitle2">9. Modification and Termination</Typography>
          <Content variant="body1">
            9.1 NAOS reserves the right to do any of the following, at any time, without notice: (a) modify, suspend or
            terminate operation of or access to or your right to use the Site and/or the Services (including the NAOS
            Finance Protocol), or any portion of the Site and/or the Services (including the NAOS Finance Protocol)
            (including your Account), for any reason at our sole discretion, including, without limitation, for any use
            of the Site and/or the Services (including the NAOS Finance Protocol) in violation of these Terms; (b)
            modify or change the Site and/or the Services (including the NAOS Finance Protocol), or any portion of the
            Site and/or the Services (including the NAOS Finance Protocol), and any applicable policies or terms; and
            (c) interrupt the operation of the Site and/or the Services (including the NAOS Finance Protocol), or any
            portion of the Site and/or the Services (including the NAOS Finance Protocol), as necessary to perform
            routine or non-routine maintenance, error correction, or other changes. Upon termination of your rights
            under these Terms, your Account and right to access and use the Site and/or the Services (including the NAOS
            Finance Protocol) will terminate immediately. Even after your rights under these Terms are terminated, the
            provisions of these Terms (other than Section 1.2) will remain in effect.
          </Content>
          <Content variant="body1">
            9.2 You acknowledge and agree that NAOS will not be liable to you or to any third party for any
            modification, suspension, or discontinuation of the Site and/or the Services or any part thereof or any
            termination of your rights under these Terms, including for termination of your Account.
          </Content>
        </Section>
        <Section>
          <Typography variant="subtitle2">10. No Reliance or Maintenance</Typography>
          <Content variant="body1">
            While NAOS uses reasonable efforts to update the information contained in the Site and/or the Services, NAOS
            makes no representations or warranties as to the accuracy, reliability or completeness of any information
            at, on or available through the Site and/or the Services. Any content of the Site and/or the Services is
            subject to change without notice. As noted above, permission by NAOS to access the Site and/or the Services
            should not be construed as, or relied upon as, investment, financial, legal, regulatory, accounting, tax or
            similar advice. You acknowledge and agree that NAOS will have no obligation to provide you with any support
            or maintenance in connection with the Site and/or the Services.
          </Content>
        </Section>
        <Section>
          <Typography variant="subtitle2">11. Governing Law</Typography>
          <Content variant="body1">
            You agree that all matters relating to your access to or use of the Site and/or the Services, including all
            disputes, will be governed by the laws of the Cayman Islands without regard to any conflicts of laws
            provisions principles that require the application of the law of a different jurisdiction.
          </Content>
        </Section>
        <Section style={{ marginBottom: 80 }}>
          <Typography variant="subtitle2">12. Miscellaneous</Typography>
          <Content variant="body1">
            12.1 These Terms constitute the entire agreement between you and NAOS with regard to your use of the Site
            and the Services, and any and all other written or oral agreements or understandings previously existing
            between you and NAOS with respect to such use are hereby superseded and cancelled. In the event any
            provision of these Terms is determined to be invalid or unenforceable, such provision shall be deemed
            severed from the remainder of these Terms and replaced with a valid and enforceable provision as similar in
            intent as reasonably possible to the provision so severed, and shall not cause the invalidity or
            unenforceability of the remainder of these Terms.
          </Content>
          <Content variant="body1">
            12.2 Our rights under these Terms may be waived by NAOS only in writing. NAOS’s failure to insist on or
            enforce strict performance of these Terms shall not be construed as a waiver by NAOS of any provision or any
            right it has to enforce these Terms, nor shall any course of conduct between NAOS and you or any other party
            be deemed to modify any provision of these Terms. These Terms shall not be interpreted or construed to
            confer any rights or remedies on any third parties.
          </Content>
          <Content variant="body1">
            12.3 NAOS shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these
            Terms without any notification or consent required. However, you shall not be permitted to assign, transfer,
            or subcontract any of your rights and/or obligations under these Terms.
          </Content>
        </Section>
        <Section style={{ marginBottom: 80 }}>
          <Typography variant="subtitle2">13. Contact</Typography>
          <Content variant="body1">
            If you have any questions, comments, or concerns regarding these Terms, or wish contact us for any reason,
            please contact us at support@naos.finance. These Terms were updated as of July, 12, 2021.
          </Content>
          <Content variant="body1">Copyright © 2021 NAOS. All rights reserved.</Content>
        </Section>
      </Container>
      <Footer />
    </WapperStyle>
  )
}

export default Terms
