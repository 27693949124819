import { styled } from '@mui/material'
import React from 'react'
import { SCREEN_TYPE } from '../../../config/screenConfig'

const VideoBlockStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  zIndex: 1,
  margin: '150px 0 100px 0',
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {},
}))

const VideoStyled = styled('iframe')(({ theme }) => ({
  width: '680px',
  height: '382.5px',
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    width: '350px',
    height: '197px',
  },
}))

const VideoGroup = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: '38px 40px',
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    padding: '19px 20px',
  },
}))

const VideoBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: '-1',
  background: `rgba(255, 255, 255, 0.26)`,
  boxShadow: `0 0 5px 3px rgba(255, 255, 255, 0.26)`,
  borderRadius: `16px`,
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {},
}))

export const Video = React.memo(() => {
  return (
    <VideoBlockStyled>
      <VideoGroup>
        <VideoBackground />
        <VideoStyled
          className="intro-video"
          src="https://www.youtube.com/embed/-tyzySpZBJk?rel=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoGroup>
    </VideoBlockStyled>
  )
})
