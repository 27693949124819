import { useEffect, useMemo, useState } from 'react'
import { GALAXY_API_HOST } from '../config/endPoint'

export function useStakingPoolsAprs() {
  const [aprs, setTvls] = useState<Object | null>(null)

  useEffect(() => {
    const setData = () =>
      fetch(`${GALAXY_API_HOST}/api/v1/farm/aprs`).then((res) =>
        res.json().then(({ aprs }) => {
          const val = {
            eth: aprs['eth'],
            bsc: aprs['bsc'],
          }
          setTvls(val)
        })
      )

    setData()
  }, [])

  return useMemo(() => aprs, [aprs])
}
