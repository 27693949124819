import { Theme } from '@mui/material'
import { styled } from '@mui/system'
import { SCREEN_TYPE } from '../../../../config/screenConfig'

const BACKGROUND_ICON_WIDTH = window.innerWidth > 1800 ? window.innerWidth : 1800
const MAIN_BLOCK_WIDTH = 700
const MOBILE_BACKGROUND_ICON_WIDTH = 1200
const MOBILE_MAIN_BLOCK_WIDTH = 200
const ANMATION_SEC = 0.2

export const SectionStyle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'style',
})<{ theme?: Theme; style?: React.CSSProperties }>(({ theme, style }) => ({
  height: '1200px',
  position: 'absolute',
  top: '0px',
  width: '100vw',
  transition: `all ${ANMATION_SEC}s linear`,

  '.veiw-block': {
    position: 'relative',

    ' .coinIconGrop': {
      margin: 'auto',
      position: 'relative',
      width: MAIN_BLOCK_WIDTH,
      transition: `all ${ANMATION_SEC}s linear`,

      [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
        width: MOBILE_MAIN_BLOCK_WIDTH,
      },
    },

    '.text-block': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      paddingTop: '180px',
      transition: `all ${ANMATION_SEC}s linear`,

      [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
        paddingTop: '110px',
      },
    },

    '.white-naos-icon': {
      display: 'block',
      margin: 'auto',
      width: '250px',
      transition: `all ${ANMATION_SEC}s linear`,

      [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
        width: '200px',
      },
    },

    '.coin-icon-1': {
      width: '360px',
      transform: 'scaleX(-1)',
      position: 'absolute',
      right: '-20px',
      top: '480px',
      transition: `all ${ANMATION_SEC}s linear`,

      [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
        width: '190px',
        top: '430px',
        right: '-70px',
      },
    },

    '.coin-icon-2': {
      width: '225px',
      position: 'absolute',
      left: '-20px',
      top: '480px',
      transition: `all ${ANMATION_SEC}s linear`,

      [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
        width: '110px',
        top: '380px',
        left: '-60px',
      },
    },

    '.blurrcoin-1': {
      width: '85px',
      position: 'absolute',
      right: '140px',
      top: '360px',
      transform: 'matrix(-1, 0, 0, 1, 0, 0)',
      filter: 'blur(4px)',
      transition: `all ${ANMATION_SEC}s linear`,

      [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
        width: '40px',
        top: '350px',
        right: '5px',
      },
    },

    '.blurrcoin-2': {
      position: 'absolute',
      top: '324px',
      left: '171px',
      transition: `all ${ANMATION_SEC}s linear`,

      [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
        width: '50px',
        top: '330px',
        left: '2px',
        opacity: 0.5,
      },
    },
  },

  ...style,
}))

export const BackgroundImg = styled('div', {
  shouldForwardProp: (prop) => prop !== 'img',
})<{ theme?: Theme; img: string }>(({ theme, img }) => ({
  position: 'absolute',
  top: 0,
  zIndex: -1,
  width: '100vw',
  height: '100vh',

  backgroundImage: `url(${img})`,
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: `${BACKGROUND_ICON_WIDTH}px ${BACKGROUND_ICON_WIDTH}px`,

  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    backgroundSize: `${MOBILE_BACKGROUND_ICON_WIDTH}px ${MOBILE_BACKGROUND_ICON_WIDTH}px`,
  },
}))

export const TitleIcon = styled('img')(({ theme }) => ({
  width: '400px',
  paddingBottom: '30px',
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    width: '250px',
    paddingBottom: '10px',
  },
}))

export const Content = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '26px',
  padding: '25px 0 70px 0',
  lineHeight: '43px',
  textAlign: 'center',
  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    padding: '20px 0 35px 0',
    fontSize: '20px',
    minWidth: '305px',
  },
}))
