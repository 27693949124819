import { Routes, Route, Navigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { Provider as ReduxProvider } from 'react-redux'

import theme from '../theme'
// import Invest from './Lend'
// import Home, { SECTION_NAME } from './Home'
// import Follow from './Follow'
import Header from '../components/header'
import MainPanel from './mainPanel'
import store from '../state/store'
import Terms from './terms'
import Privacy from './privacy'
import BrandAssets from './BrandAssets'
// import SettingBar from '../components/settingBar'

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <Header />
        {/* <SettingBar /> */}
        <Routes>
          <Route path="/" element={<MainPanel />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/brand-assets" element={<BrandAssets />} />
          {/* <Route path="/lend" element={<Invest />}></Route>
          <Route path="/follow" element={<Follow />}></Route> */}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </ReduxProvider>
    </ThemeProvider>
  )
}
export default App
