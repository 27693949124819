import { createTheme, responsiveFontSizes } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    text: {
      primary: '#ffffff',
    },
    action: {
      // hover: 'currentColor',
      hoverOpacity: 0,
    },
    background: {
      default: 'linear-gradient(90deg, #13207b 1%, #361f6a 100%)',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
        },
      },
    },
  },

  typography: {
    fontFamily: 'Inter',
    h2: {
      fontFamily: 'Inter',
      fontSize: 30,
      fontStyle: 'normal',
      color: '#ffffff',
    },
    h3: {
      fontFamily: 'Inter',
      fontSize: 24,
      fontWeight: 500,
      fontStyle: 'normal',
      color: '#ffffff',
    },
    h4: {
      fontFamily: 'Inter',
      fontSize: 20,
      fontWeight: 500,
      fontStyle: 'normal',
      color: '#ffffff',
    },
    h5: {
      fontFamily: 'Inter',
      fontSize: 16,
      fontWeight: 300,
      fontStyle: 'normal',
      color: '#ffffff',
    },
    h6: {
      fontFamily: 'Inter',
      fontSize: 11,
      fontStyle: 'normal',
      color: '#ffffff',
    },
    subtitle1: {
      fontFamily: 'Inter',
      fontSize: 20,
      fontWeight: 600,
      fontStyle: 'normal',
      color: '#ffffff',
    },
    subtitle2: {
      fontSize: 16,
      color: '#ffffff',
    },
    body1: {
      fontSize: 16,
      color: '#ffffff',
    },
    body2: {
      fontSize: 11,
      color: '#ffffff',
    },
  },
})

export default responsiveFontSizes(theme)
