import { styled } from '@mui/system'

export const WapperStyle = styled('div')({
  overflowX: 'hidden',
  height: '100vh',
  width: '100vw',
  position: 'relative',
  top: '0px',

  '-ms-overflow-style': 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})
