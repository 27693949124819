export const NET_TYPE = {
  BSC: 'bsc',
  ETH: 'eth',
}

export const DefaultPoolId = {
  NAOS: 0,
  NAOS_ETH_LP: 1,
  NUSD: 2,
  NUSD_3CRV: 3,
  NAOS_ETH_SLP: 255,
  BETA: 1,
  NAOS_BNB_LP: 0,
  NAOS_BOOST: 0,
}

export const ADDRESS = {
  STAKINGPOOL: '0x99e4ea9ef6bf396c49b35ff9478ebb8890aef581',
  BOOST_POOL: '0x3dcd32dd2b225749aa830ca3b4f2411bfeb03db4',
  STAKINGPOOL_WITH_TRANSFER: '0x6ebc2c41c1e29a5506b86b758b6c16dd5bbcf7d1',
}
