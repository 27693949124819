import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { useMediaQuery, useTheme } from '@mui/material'
import { Trans, t } from '@lingui/macro'
import { i18n } from '@lingui/core'

import {
  HeaderStyle,
  LogoImage,
  HeaderButton,
  LaunchButton,
  IconBlock,
  ButtonBlock,
  MobileHeaderStyle,
  MenuButton,
  MobileMenu,
  Divider,
  MobileMenuMask,
  MobileLaunchButton,
  MobileHeaderButton,
} from './styled'
import { useDispatch, useSelector } from 'react-redux'
import { HEADER_STYLE_MODE, selectHeader, setHeaderOption } from '../../state/slice/headerReducer'
import { SCREEN_TYPE } from '../../config/screenConfig'
import { SECTION_NAME } from '../../pages/mainPanel'
import MediaList from '../mediaList'

import LogoImg from '../../assets/logo/naos_logo_white.svg'
import LanguageStatus from './languageStatus'

const headerOptionsConfig: { title: string; sectionName: SECTION_NAME }[] = [
  {
    title: t`Pool`,
    sectionName: SECTION_NAME.POOL,
  },
  {
    title: t`Protocol`,
    sectionName: SECTION_NAME.PROTOCOL,
  },
  {
    title: t`Investors`,
    sectionName: SECTION_NAME.INVESTORS,
  },
]

const MobileHeader = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const dispatch = useDispatch()
  const styleMode = useSelector(selectHeader)?.mode
  const navigate = useNavigate()
  const location = useLocation()

  const handleOptionClick = (name: SECTION_NAME) => {
    if (location.pathname !== '/') {
      navigate('/', { replace: true })
    }

    dispatch(setHeaderOption(name))
    handleMenuOpen(false)
  }

  const handleMenuOpen = (menuState?: boolean) => {
    setMenuOpen(menuState ? menuState : !menuOpen)
  }

  return (
    <>
      <MobileHeaderStyle mode={menuOpen ? HEADER_STYLE_MODE.CONTENT : styleMode}>
        <IconBlock onClick={() => handleOptionClick(SECTION_NAME.HOME)}>
          <LogoImage style={{ width: '120px', marginLeft: '15px' }} alt="" src={LogoImg} />
        </IconBlock>
        <ButtonBlock>
          <LanguageStatus />
        </ButtonBlock>
        <MenuButton onClick={() => handleMenuOpen()}>{menuOpen ? <CloseIcon /> : <MenuIcon />}</MenuButton>
      </MobileHeaderStyle>

      <MobileMenu open={menuOpen}>
        <div className="inner-block">
          <MobileLaunchButton onClick={() => window.open('https://app.naos.finance', '_blank', 'noopener noreferrer')}>
            <Trans>Launch app</Trans>
          </MobileLaunchButton>
          <Divider />
          {headerOptionsConfig.map((config) => (
            <MobileHeaderButton
              key={`header-option-${config.title}`}
              onClick={() => handleOptionClick(config.sectionName)}
              disableRipple
            >
              {i18n._(config.title)}
            </MobileHeaderButton>
          ))}

          <MediaList
            style={{
              marginTop: '25px',
              alignItems: 'center',
            }}
            iconStyle={{
              margin: '14px 8px',
            }}
          />
        </div>
      </MobileMenu>

      {menuOpen && <MobileMenuMask onClick={() => handleMenuOpen()} />}
    </>
  )
}

const PCHeader = () => {
  const dispatch = useDispatch()
  const styleMode = useSelector(selectHeader)?.mode
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = (name: SECTION_NAME) => {
    if (location.pathname !== '/') {
      navigate('/', { replace: true })
    }

    dispatch(setHeaderOption(name))
  }

  return (
    <HeaderStyle mode={styleMode}>
      <IconBlock onClick={() => handleClick(SECTION_NAME.HOME)}>
        <LogoImage alt="" src={LogoImg} />
      </IconBlock>
      <ButtonBlock>
        {headerOptionsConfig.map((config) => (
          <HeaderButton
            key={`header-option-${config.title}`}
            onClick={() => handleClick(config.sectionName)}
            disableRipple
          >
            {i18n._(config.title)}
          </HeaderButton>
        ))}
      </ButtonBlock>
      <ButtonBlock>
        <LanguageStatus />
      </ButtonBlock>
      <LaunchButton onClick={() => window.open('https://app.naos.finance', '_blank', 'noopener noreferrer')}>
        <Trans>Launch app</Trans>
      </LaunchButton>
    </HeaderStyle>
  )
}

const Header = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(SCREEN_TYPE.MOBILE))
  return isMobile ? <MobileHeader /> : <PCHeader />
}

export default Header
