import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { useMediaQuery, useTheme } from '@mui/material'
import { Trans, t } from '@lingui/macro'
import { i18n } from '@lingui/core'
import { SCREEN_TYPE } from '../../config/screenConfig'
import { activate, LANGUAGE } from '../LanguageProvider'
import styled from '@emotion/styled'

import LanguageIcon from '@mui/icons-material/Language'

const LanguageStatusStyle = styled.div`
  color: white;
  display: flex;
  margin-left: 10px;
  position: relative;
`

const LanguageMenu = styled.div`
  max-height: 150px;
  position: absolute;
  right: -17px;
  background: rgb(110 101 139 / 95%);
  top: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-x: hidden;
  text-align: center;
  border-radius: 8px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid rgb(255, 255, 255, 0.4);
    background-color: rgba(255, 255, 255, 0.4);
  }
`

const LanguageOption = styled.div<{ disable: boolean }>`
  cursor: pointer;
  white-space: pre;
  margin: 7px 0;
  min-width: 60px;

  &:hover {
    opacity: 0.5;
  }
`

const OpenButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.5;
  }
`

const langaugeOptionName = {
  [LANGUAGE.EN]: 'EN',
  [LANGUAGE.ZHCN]: '简中',
  [LANGUAGE.ZHTW]: '繁中',
  [LANGUAGE.KO]: '한국어',
  [LANGUAGE.RU]: 'Русский',
  [LANGUAGE.TR]: 'Türkçe',
  [LANGUAGE.ES]: 'Español',
  [LANGUAGE.PT]: 'Português',
  [LANGUAGE.VI]: 'Tiếng Việt',
}

const LanguageStatus = () => {
  const [open, setOpen] = useState<boolean>(false)

  const local = i18n.locale
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(SCREEN_TYPE.MOBILE))

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSlectLangauge = (option) => {
    if (option && local !== option) {
      activate(option)
    }
    handleClose()
  }

  return (
    <LanguageStatusStyle>
      <OpenButton onMouseEnter={handleOpen} onMouseLeave={handleClose}>
        <LanguageIcon />
      </OpenButton>
      {open && (
        <LanguageMenu onMouseEnter={handleOpen} onMouseLeave={handleClose}>
          {Object.keys(langaugeOptionName).map((key) => (
            <LanguageOption
              key={`language-option-${key}`}
              disable={local === key}
              onClick={() => handleSlectLangauge(key)}
            >
              {langaugeOptionName[key]}
            </LanguageOption>
          ))}
        </LanguageMenu>
      )}
    </LanguageStatusStyle>
  )
}

export default LanguageStatus
