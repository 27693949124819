import { styled } from '@mui/system'
import { Trans } from '@lingui/macro'

import PartnerCarousel from './slideshow/PartnerSlideshow'

import logo01 from '../../../assets/investors/coinBase.png'
import logo02 from '../../../assets/investors/hupbiV.png'
import logo03 from '../../../assets/investors/okex.png'
import logo04 from '../../../assets/investors/cryptoCom.png'
import logo05 from '../../../assets/investors/mxc.png'

import logo06 from '../../../assets/investors/mechanismC.png'
import logo07 from '../../../assets/investors/spartan.png'
import logo08 from '../../../assets/investors/lemnsicop.svg'
import logo09 from '../../../assets/investors/colliderV.png'
import logo10 from '../../../assets/investors/IncubaA.png'

import logo11 from '../../../assets/investors/defiA.png'
import logo12 from '../../../assets/investors/cms.png'
import logo13 from '../../../assets/investors/hashkey.png'
import logo14 from '../../../assets/investors/gbv.png'
import logo15 from '../../../assets/investors/a&tCapital.png'

import logo16 from '../../../assets/investors/maven11.png'
import logo17 from '../../../assets/investors/waterdRip.png'
import logo18 from '../../../assets/investors/youbiC.png'
import logo19 from '../../../assets/investors/gbic.png'
import logo20 from '../../../assets/investors/polkastartar.png'

import auditedLogo1 from '../../../assets/icon/icon_certik.png'
import auditedLogo2 from '../../../assets/icon/icon_quantstamp.svg'
import { Typography } from '@mui/material'
import { SCREEN_TYPE } from '../../../config/screenConfig'

const SectionStyle = styled('div')(({ theme }) => ({
  minHeight: '800px',
  padding: '80px 50px 50px 50px',
  color: theme.palette.text.primary,
  fontFamily: 'Inter',

  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    padding: '80px 20px 10px 20px',
    minHeight: '400px',
  },
}))

const TextBlock = styled('div')({
  margin: 'auto',
  maxWidth: `810px`,
  borderRadius: `13px`,
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
})

const ListBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',

  // [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
  //   margin: '30px 0 100px 0',
  // },
}))

const CompanyTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  fontSize: '30px',
  textAlign: 'center',
  margin: '120px 0 20px 0',

  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    fontSize: '24px',
    margin: '90px 0 10px 0',
  },
}))

const Icon = styled('img')(({ theme }) => ({
  margin: '30px 0px',
  width: '210px',

  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    margin: '10px 0px',
    width: '110px',
  },
}))

//auditedLogo1
const AuditedIcon = styled(Icon)(({ theme }) => ({
  margin: '30px 25px',
  width: '290px',

  [theme.breakpoints.down(SCREEN_TYPE.MOBILE)]: {
    margin: '20px 5px',
    width: '140px',
  },
}))

const Investors = () => {
  return (
    <SectionStyle>
      <TextBlock>
        <CompanyTitle variant={'h2'}>
          <Trans>INVESTORS</Trans>
        </CompanyTitle>
      </TextBlock>
      <ListBlock>
        <Icon alt="" src={logo01} />
        <Icon alt="" src={logo02} />
        <Icon alt="" src={logo03} />
        <Icon alt="" src={logo04} />
        <Icon alt="" src={logo05} />
        <Icon alt="" src={logo06} />
        <Icon alt="" src={logo07} />
        <Icon alt="" src={logo08} />
        <Icon alt="" src={logo09} />
        <Icon alt="" src={logo10} />
        <Icon alt="" src={logo11} />
        <Icon alt="" src={logo12} />
        <Icon alt="" src={logo13} />
        <Icon alt="" src={logo14} />
        <Icon alt="" src={logo15} />
        <Icon alt="" src={logo16} />
        <Icon alt="" src={logo17} />
        <Icon alt="" src={logo18} />
        <Icon alt="" src={logo19} />
        <Icon alt="" src={logo20} />
      </ListBlock>

      <TextBlock>
        <CompanyTitle>
          <Trans>STRATEGIC PARTNERS</Trans>
        </CompanyTitle>
      </TextBlock>
      <PartnerCarousel />

      <TextBlock>
        <CompanyTitle>
          <Trans>AUDITED BY</Trans>
        </CompanyTitle>
      </TextBlock>

      <ListBlock>
        <AuditedIcon alt="" src={auditedLogo1} />
        <AuditedIcon alt="" src={auditedLogo2} />
      </ListBlock>
    </SectionStyle>
  )
}

export default Investors
